
// Libraries
import * as React from 'react'

// Services
import ApiService from "../services/api"
import UserService from "../services/user"

// Components
import Layout from '../components/layout'
import DuoSection from '../components/duoSection'
import Seo from '../components/seo'
import InputFactory from '../components/inputFactory'
import Button from '../components/button'
import { Link }  from 'gatsby'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faHandRock} from '@fortawesome/pro-regular-svg-icons'

// Context
import NotificationContext from '../contexts/notification'

class LoginQuestionPage extends React.Component {
	state = {
		isButtonDisabled: true,
		isButtonSuccess: false,
		user: {},
		question: {},
		noneAnswerId: null,
		inputValues: {},
		inputErrors: {},
	}

	static contextType = NotificationContext

	render() {
		const {question, isButtonDisabled, isButtonSuccess, noneAnswerId} = this.state

		return <Layout className="page--login-question nav-blue-half">
			<Seo title="Login Question" />
			<DuoSection login admin-login>
				<div>
					<div className="content-wrap">
						<p className="color--endeavour intro"><b>Tell us a little bit more about you</b></p>
						{question?.id
							? <form onSubmit={this.handleSubmit}>
									<InputFactory
										type={question.answer_type}
										name={`answers`}
										label={question.text}
										options={question.answers}
										onChange={this.handleInputChange}
										noneAnswerId={noneAnswerId}
									/>
									<p className='buttons-wrap'>
										<Link to="/" className="link--text color--endeavour">SKIP THIS QUESTION</Link>
										<Button isLoading={isButtonDisabled} isSuccess={isButtonSuccess} type="submit" colorEndeavour xsBlock>
											<span className='default'>
												Next
											</span>
											<span className='thanks'>
												<FontAwesomeIcon icon={faHandRock} title="Thanks icon" />
												Thank you!
											</span>
										</Button>
									</p>
								</form>
							: <legend>Loading question ...</legend>
						}
					</div>
				</div>
				<div>
				</div>
			</DuoSection>
		</Layout>
	}

	componentDidMount() {
		const {addNotification} = this.context

		this.getQuestion()
			.catch(() => addNotification('There was an error fetching the question.', 'error'))
	}

	getQuestion = async () => {
		const userService = new UserService()
		const apiService = new ApiService()

		await userService.getUser().then((response) => {
			this.setState({
				user: response.data
			})

			return apiService.get(`users/${response.data.id}/question`).then( (response) => {
				response.data.answers.forEach((answer) => {
					if (!answer.tag_to_apply) {
						this.setState({
							noneAnswerId: answer.id
						})
					}
				})

				this.setState({
					question: response.data
				})
			}).catch(err => {
				this.props.navigate('/')
			})
		})
	}

	handleInputChange = (value, name, scope) => {
		// Get current form data from state
		const {inputValues, question} = this.state
		let isButtonDisabled

		if (value !== undefined) {
			// Update field value

			// if radio group turn value into array
			if (question.answer_type === 'radio-group') {
				value = [value]
			}

			if (scope) {
				inputValues[scope][name] = value
			}
			else {
				inputValues[name] = value
			}

			isButtonDisabled = false
		} else {
			// Remove field value
			if (scope) {
				delete inputValues[scope][name]
			}
			else {
				delete inputValues[name]
			}

			isButtonDisabled = true
		}

		// Update state of file input values
		this.setState({
			inputValues: inputValues,
			isButtonDisabled: isButtonDisabled
		})
	}

	handleSubmit = async (submitEvent) => {
		submitEvent.preventDefault()
		this.setState({
			isButtonDisabled: true
		})

		const {inputValues, user, question} = this.state
		const apiService = new ApiService()
		const {addNotification} = this.context

		// submit venue data
		const answerData = JSON.stringify(inputValues)
		await apiService.post(`users/${user.id}/question/${question.id}/answer`, {body: answerData})
			.then(async (answerResponse) => {
				if (answerResponse.success) {
					this.setState({
						isButtonSuccess: true
					})

					setTimeout(() => {
						this.props.navigate('/')
					}, 2000)
				}
				else {
					this.setState({
						inputErrors: answerResponse.errors,
						isButtonDisabled: false
					})

					addNotification(answerResponse.errors.answers, 'error')
				}
			})
			.catch(err => {
				console.error(err)

				addNotification('Answer failed to save', 'error')
			})
	}
}

export default LoginQuestionPage
